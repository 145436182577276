<template>
  <div class="text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Frequently Asked Questions</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="text-justify">
        We get a lot of questions, so we'll post some of the most common ones here.
      </div>
    </div>
    <div class="mb-4 px-4 py-2 border-bottom">
      <div class="mb-4 text-justify">
        <h5>
          What is Airframes?
        </h5>
        <p>
          Airframes is an unbiased &amp; unfiltered transportation aggregation service that was
          initially architected, designed &amp; built by Kevin Elliott. It continues to be
          actively supported by many other community contributors and partners. Unlike other
          aggregators, Airframes is focused on information such as diagnostic, maintenance, and
          operational messages, and rather than ADS-B, it is derived from ACARS, VDL, HFDL, and
          SATCOM.
        </p>
        <p>
          The data available here is sourced from volunteer feeders, aggregated, processed,
          stored, and then interesting revelations are surfaced.
        </p>
      </div>
    </div>
    <div class="mb-4 px-4 py-2 border-bottom">
      <div class="text-justify">
        <h5>
          What makes Airframes different from other aggregators?
        </h5>
        <p>
          Unlike large aggregators like FlightAware, the data available here is not based on
          any contracted government data, and is primarily sourced by volunteer feeders in the
          community. Additionally, the data available here is not filtered or biased. Almost all
          other aggregators are filtered due to government mandates under contract in order to use
          their data, preventing the use of certain information. Airframes does not have this
          restriction.
        </p>
        <p>
          Additionally, Airframes does not have the same budgets or resources as these other
          aggregators, and you might notice that we are not easily able to present all of the
          features that other aggregators have. We are working to make Airframes better, and
          welcome contributions (see below).
        </p>
      </div>
    </div>
    <div class="mb-4 px-4 py-2 border-bottom">
      <div class="text-justify">
        <h5>
          What can I do with Airframes?
        </h5>
        <p>
          Some of the things you can do with Airframes are as follows:
        </p>
        <ul>
          <li>View/search for aviation data (messages, flights, airports, airlines, etc.)</li>
          <li>View/search for marine data (messages, ships, ports, etc.)</li>
          <li>Contribute data by feeding data using decoder clients</li>
          <li>Decode messages using the Airframes decoder</li>
        </ul>
      </div>
    </div>
    <div class="mb-4 px-4 py-2 border-bottom">
      <div class="text-justify">
        <h5>
          How can I help?
        </h5>
        <p>
          Thank you for thinking of helping! Here are some ideas for how to help.
        </p>
        <ul>
          <li>
            Consider helping to
            <a href="https://github.com/airframesio/acars-message-documentation" target="_blank">document the
            format of this message text</a>, after which if you are feeling like you have the chops,
            <a href="https://github.com/airframesio/acars-decoder-typescript" target="_blank">submit a Pull Request
            to add decoding support</a>.
          </li>
          <li>
            Tell others about Airframes by sharing your thoughts on social media. It is a great
            way to get feedback, help others, and spread the word.
            Feel free to tag us if you do.
          </li>
          <li>
            Write an article on your blog or submit to a radio, aviation or marine related news
            site. We are so heads down on building that we often
            run out of time to write articles.
          </li>
          <li>
            Contribute financially to Airframes with a monthly donation via
            <a href="https://www.patreon.com/airframes" target="_blank">our Patreon page</a>.
          </li>
          <li>
            Purchase products at the
            <a href="https://shop.airframes.io/" target="_blank">Airframes Shop</a>. Our mission
            is to bring the community the best prices for the best products, so while our margins
            are tight, every purchase does help contribute a bit to cover the cost of servers,
            data storage, and make Airframes more useful.
          </li>
          <li>
            Join the
            <a href="https://discord.gg/airframes">Airframes Discord</a> to help and support.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FAQ extends Vue {
}
</script>
