<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">HFDL</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        High Frequency Data Link
      </div>
    </div>
    <div class="container p-4">
      <h4>Ground Stations</h4>
      <p>
        Live status of HFDL ground stations and the frequencies they are currently
        listening and heard on.
        <br>
        This can be used to help you tune your HFDL decoder at
        this exact moment.
      </p>
      <table class="mt-4 table">
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Name</th>
            <th class="text-center">Active</th>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-right">Last Updated</th>
          </tr>
        </thead>
        <tr v-for="groundStation of groundStations" :key="groundStation.id">
          <td class="p-2 text-left small" style="width: 50px">{{ groundStation.id }}</td>
          <td class="p-2 text-left small" style="width: 180px">{{ groundStation.name }}</td>
          <td class="p-2 text-center small">{{ groundStation.frequencies.active[0] || '' }}</td>
          <td class="p-2 text-center small">{{ groundStation.frequencies.active[1] || '' }}</td>
          <td class="p-2 text-center small">{{ groundStation.frequencies.active[2] || '' }}</td>
          <td class="p-2 text-center small">{{ groundStation.frequencies.active[3] || '' }}</td>
          <td class="p-2 text-right small">{{ groundStation.duration_since_last_update }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class HFDL extends Vue {
  groundStations: any = [];

  created() {
    setInterval(this.fetchGroundStations, 1000);
  }
  // mounted() {
  //   this.fetchGroundStations();
  // }

  fetchGroundStations() {
    console.log('fetching ground stations');
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/hfdl/ground-stations`,
      method: 'GET',
    }).then((response) => {
      console.log('Fetched HFDL ground stations.');

      /* eslint-disable no-param-reassign */
      const { data } = response;
      this.groundStations = data.ground_stations.map((gs: any) => {
        let durationSinceLastUpdate;

        const durationSecs = (new Date().getTime() - new Date(gs.last_updated * 1000).getTime())
          / 1000;
        if (gs.last_updated === 0) {
          durationSinceLastUpdate = '-';
        } else if (durationSecs >= 24 * 3600) {
          durationSinceLastUpdate = `${Math.floor(durationSecs / (24 * 3600))}d`;
        } else if (durationSecs >= 3600) {
          durationSinceLastUpdate = `${Math.floor(durationSecs / 3600)}h`;
        } else if (durationSecs >= 60) {
          durationSinceLastUpdate = `${Math.floor(durationSecs / 60)}m`;
        } else {
          durationSinceLastUpdate = `${Math.floor(durationSecs)}s`;
        }

        gs.duration_since_last_update = durationSinceLastUpdate;
        return gs;
      });
    });
  }
}
</script>
