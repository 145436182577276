import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import Toasted from 'vue-toasted';
import VeeValidate from 'vee-validate';
import VueGtag from 'vue-gtag';
import moment from 'moment-timezone';

import '@/app.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCopy,
  faHeart,
  faPlayCircle,
  faPauseCircle,
  faSync,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

import VueLayers from 'vuelayers';
import 'vuelayers/dist/vuelayers.css';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
});

let socket;
if (process.env.NODE_ENV === 'production') {
  console.log('Running in Production mode.');
  const uri = process.env.VUE_APP_BACKEND_URI ? process.env.VUE_APP_BACKEND_URI : 'https://ws.airframes.io:443';
  socket = io(uri, {
    transports: ['websocket'],
  });
} else {
  console.log('Running in Development mode.');
  const uri = process.env.VUE_APP_BACKEND_URI ? process.env.VUE_APP_BACKEND_URI : 'https://ws.airframes.io:443';
  console.log(`Connecting to ${uri}`);
  socket = io(uri, {
    transports: ['websocket'],
  });
}

Vue.use(VueSocketIOExt, socket, { store });

library.add(faCopy);
library.add(faHeart);
library.add(faPauseCircle);
library.add(faPlayCircle);
library.add(faSpinner);
library.add(faSync);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(require('vue-moment'), {
  moment,
});

Vue.use(BootstrapVue);
Vue.use(Toasted, { position: 'bottom-right', duration: 3000, theme: 'bubble' });
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
Vue.use(VueGtag, {
  config: { id: 'G-004ZF59XP6' },
  appName: 'Airframes Web App',
  pageTrackerScreenviewEnaled: true,
}, router);

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initializeStore');
  },
  render: (h) => h(App),
}).$mount('#app');

store.subscribe((mutation, state) => {
  if (mutation.type === 'setAirframes') {
    localStorage.setItem('airframes', JSON.stringify(state.airframes));
  }

  if (mutation.type === 'setStations') {
    localStorage.setItem('stations', JSON.stringify(state.stations));
  }
});
