<template>
  <div>
    <vl-map ref="map"
      :load-tiles-while-animating="false"
      :load-tiles-while-interacting="false"
      style="height: 100%;"
      >
      <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

      <vl-layer-tile id="osm">
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>

      <vl-feature>
        <vl-geom-point
          :coordinates="[$props.coordinates.longitude, $props.coordinates.latitude]"
          >
        </vl-geom-point>
        <vl-style>
          <vl-style-circle>
            <vl-style-stroke color="blue" :width="5"></vl-style-stroke>
            <vl-style-fill color="blue"></vl-style-fill>
          </vl-style-circle>
        </vl-style>
      </vl-feature>
    </vl-map>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Map extends Vue {
  @Prop() private coordinates: any;

  zoom = this.$props.coordinates && this.$props.coordinates.latitude ? 9 : 3;

  center = this.$props.coordinates
            && this.$props.coordinates.latitude
            && this.$props.coordinates.longitude
    ? [this.$props.coordinates.longitude, this.$props.coordinates.latitude]
    : [-98.5795, 39.8283];

  rotation = 0;
}
</script>

<style scoped>
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
</style>
