<template>
  <div>
    <div id="nav" class="mt-0 pt-3 pb-3 border-bottom">
      <h3>Flights</h3>
      <router-link to="/flights">Live</router-link>
       |
      <span v-if="noFlightFound">
        Flight Not Found
      </span>
      <span v-else-if="$route.params.id">
        <router-link :to="`/flights/${$route.params.id}`">
          Flight {{ flight ? flight.flight : 'Loading...' }}
        </router-link>
      </span>
    </div>
    <div v-if="fetchingFlight" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noFlightFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Flight Not Found</h2>
    </div>

    <div v-if="flight" class="mt-4 container text-left">
      <div v-if="flight.airline.id">
        <img :src="flight.airline.logo_url" height="100" :alt="`${flight.airline.name} Logo`" />
      </div>
      <table class="table table-bordered">
        <tr class="bg-dark text-light">
          <th>ID</th>
          <th>Flight</th>
          <th>Departure</th>
          <th>Arrival</th>
          <th>Airframe</th>
          <th>Status</th>
        </tr>
        <tr class="bg-light">
          <td>{{ flight.id }}</td>
          <td>
            <div v-if="flight.flight_icao && flight.flight_iata">
              {{ flight.flight_icao }} / {{ flight.flight_iata }}
            </div>
            <div v-else-if="flight.flight_icao">
              {{ flight.flight_icao }}
            </div>
            <div v-else-if="flight.flight_iata">
              {{ flight.flight_iata }}
            </div>
            <div v-else>
              {{ flight.flight }}
            </div>
          </td>
          <td>{{ flight.departingAirport }}</td>
          <td>{{ flight.destinationAirport  }}</td>
          <td>
            <router-link :to="`/airframes/${flight.airframe.id}`">
              {{ flight.airframe.tail }}
            </router-link>
          </td>
          <td>
            {{ flight.status }}
          </td>
        </tr>
      </table>

      <div class="mb-4">
        <h5>Events ({{ flight.events.length }})</h5>
        <table
          v-if="flight.events && flight.events.length > 0"
          class="mb-4 table table-bordered table-sm small">
          <tr>
            <th class="text-left">Timestamp</th>
            <th class="text-left">Code</th>
            <th class="text-left">Detail</th>
          </tr>
          <tr v-for="event in flight.events" :key="`fd-message-event-${event.id}`">
            <td class="text-left text-nowrap">
              <span :title="event.eventAt">
                {{ event.event_at | moment("MMM DD YYYY, HH:mm:ss") }}
              </span>
            </td>
            <td class="text-left text-nowrap">{{ event.code }}</td>
            <td class="text-left">{{ event.details }}</td>
          </tr>
        </table>
        <div v-else-if="fetchingFlightEvents" class="text-left">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-else-if="errorFetchingFlightEvents" class="text-left">
          <p>{{ errorFetchingFlightEventsMessage }}</p>
        </div>
        <div v-else>
          <p>No events found.</p>
        </div>
      </div>

      <h5>Messages ({{ flight.messages.length }})</h5>
      <div v-if="flight.messages.length > 0">
        <table class="mb-4 table table-bordered table-sm small">
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Timestamp</th>
            <th class="text-left">Source</th>
            <th class="text-left">Position</th>
            <th class="text-left">Label</th>
            <th class="text-left">Block ID</th>
            <th class="text-left">Msg #</th>
            <th class="text-left">Text</th>
          </tr>
          <tr v-for="message in flight.messages"
            :key="`fd-message-${message.id}`"
            >
            <td class="text-left">
              <router-link :to="`/messages/${message.id}`">
                {{ message.id }}
              </router-link>
            </td>
            <td class="text-left">
              <span :title="message.timestamp">
                {{ message.received_at | moment("MMM DD YYYY, HH:mm:ss") }}
              </span>
            </td>
            <td class="text-left">{{ message.source_type.toUpperCase() }}</td>
            <td class="text-left">
              <span v-if="message.latitude && message.longitude">
                {{ message.latitude }}, {{ message.longitude }}
              </span>
            </td>
            <td class="text-left">{{ message.label }}</td>
            <td class="text-left">{{ message.block_id }}</td>
            <td class="text-left">{{ message.message_number }}</td>
            <td class="text-wrap text-break w-50">{{ message.text }}</td>
          </tr>
        </table>
      </div>
      <div v-else-if="fetchingFlightMessages" class="text-left">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else-if="errorFetchingFlightMessages" class="text-left">
        <p>{{ errorFetchingFlightMessagesMessage }}</p>
      </div>
      <div v-else>
        <p>No messages found.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';

Vue.use(VueAxios, axios);

@Component({
  components: {},
})
export default class FlightDetail extends Vue {
  flight: any = null;

  fetchingAirline = false;

  errorFetchingAirline = false;

  errorFetchingAirlineMessage = '';

  fetchingFlight = false;

  errorFetchingFlight = false;

  errorFetchingFlightMessage = '';

  fetchingFlightEvents = false;

  errorFetchingFlightEvents = false;

  errorFetchingFlightEventsMessage = '';

  fetchingFlightMessages = false;

  errorFetchingFlightMessages = false;

  errorFetchingFlightMessagesMessage = '';

  noFlightFound = false;

  mounted() {
    this.flight = null;
    this.refresh();
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.flight = null;
      this.refresh();
    }
  }

  refresh() {
    this.fetchFlight();
  }

  async fetchAirline() {
    if (this.flight && !!this.flight.airline_id) {
      this.fetchingAirline = true;
      console.log('Fetching airline...');
      const url = `https://toad.airframes.io/v1/aircraft/airlines/${this.flight.airline_id}`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched airline.');
        console.log(response.data);
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingAirline = false;
          this.errorFetchingAirline = true;
          this.errorFetchingAirlineMessage = response.data.message;
        } else {
          this.flight.airline = response.data.airline;
          this.fetchingAirline = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingAirline = false;
      });
    }
  }

  async fetchFlightEvents() {
    if (this.flight) {
      this.fetchingFlightEvents = true;
      console.log('Fetching flight events...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.flight.id}/events`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched flight events.');
        console.log(response.data);
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlightEvents = false;
          this.errorFetchingFlightEvents = true;
          this.errorFetchingFlightEventsMessage = response.data.message;
        } else {
          this.flight.events = response.data.events;
          this.fetchingFlightEvents = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingFlightEvents = false;
      });
    }
  }

  async fetchFlight() {
    this.flight = null;
    if (this.$route.params) {
      this.fetchingFlight = true;
      console.log('Fetching flight detail...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.$route.params.id}`;
      Vue.axios.get(url).then(async (response) => {
        console.log('Fetched flight detail.');
        console.log(response.data);
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlight = false;
          this.errorFetchingFlight = true;
          this.errorFetchingFlightMessage = response.data.message;
          this.noFlightFound = true;
          return;
        }
        const { flight } = response.data;
        flight.airframe = flight.airframe || {};
        flight.airline = flight.airline || {};
        flight.events = flight.events || [];
        flight.messages = flight.messages || [];
        flight.messages = flight.messages.sort((a: any, b: any) => { // eslint-disable-line max-len, arrow-body-style
          return a.timestamp > b.timestamp ? 1 : -1;
        });
        this.flight = flight;
        this.fetchingFlight = false;
        await this.fetchAirline();
        await this.fetchFlightEvents();
        await this.fetchMessages();
      }).catch((error) => {
        console.error(error);
        this.fetchingFlight = false;
        this.noFlightFound = true;
      });
    }
  }

  async fetchMessages() {
    if (this.flight) {
      this.fetchingFlightMessages = true;
      console.log('Fetching flight messages...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.flight.old_id}/messages`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched flight messages.');
        console.log(response.data);
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlightMessages = false;
          this.errorFetchingFlightMessages = true;
          this.errorFetchingFlightMessagesMessage = response.data.message;
        } else {
          this.flight.messages = response.data.messages;
          this.fetchingFlightMessages = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingFlightMessages = false;
      });
    }
  }
}
</script>
