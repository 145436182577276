<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        :src="avatar.imageUrl(user, { size: 100 })"
        class="profile-img-card"
        alt="Avatar"
      />
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            v-model="user.email"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="email"
            @change="handleEmailChanged"
          />
          <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
          >Email is required!</div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="isLoading">
            <span v-show="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            <span>Resend Confirmation Email</span>
          </button>
        </div>
        <div class="form-group">
          <div
            v-if="message"
            class="alert"
            :class="{ 'alert-danger': isError, 'alert-success': !isError }"
            role="alert"
            >{{message}}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import User from '@/models/user';
import Avatar from '@/utilities/avatar';

@Component({
  components: {
  },
})
export default class ResendConfirmation extends Vue {
  avatar = Avatar;

  user = new User('', '');

  isError = false;

  isLoading = false;

  message = '';

  created() {
    if (this.loggedIn()) {
      Vue.toasted.show('Already logged in.', { type: 'info' });
      this.$router.push('/user/dashboard');
    }
  }

  loggedIn() {
    return this.$store.state.auth.status.loggedIn;
  }

  handleEmailChanged() {
    console.log(this.user.email);
  }

  handleLogin() {
    this.isLoading = true;
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        this.isLoading = false;
        return;
      }
      if (this.user.email) {
        this.$store.dispatch('auth/resendConfirmation', this.user).then(
          () => {
            this.isLoading = false;
            this.isError = false;
            this.message = 'Confirmation email has been sent. Be sure to check your spam folder.';
            Vue.toasted.success('Confirmation email has been sent. Be sure to check your spam folder.');
          },
          (error) => {
            this.isLoading = false;
            this.isError = true;
            this.message = (error.response && error.response.data)
              || error.message
              || error.toString();
          },
        );
      }
    });
  }
}
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
