<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="mb-4 border">
    <div @click="showMessageDetail">
      <MessageItemHeader :message="message" />
      <div class="border-bottom border-top text-black">
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-row">
              <div class="w-100 text-break text-left">
                <div class="p-3 bg-inner inner-shadow">
                  <span v-if="message.text || message.data">
                    <div v-html="convertNewlinesToBRs(message.text || message.data)"></div>
                    <div v-if="decodedMessage.decoded">
                      <div class="mt-1 text-muted"><small>DECODED</small></div>
                      <div v-if="decodedMessage.formatted">
                        <div>{{ decodedMessage.formatted.description }}</div>
                        <b-table small bordered caption-top
                          :caption="decodedMessage.formatted.description"
                          :items="decodedMessage.formatted.items"
                          :fields="['label', 'value']"
                          >
                        </b-table>
                      </div>
                      <div v-else v-html="decodedMessage"></div>
                    </div>
                  </span>
                  <span v-else class="text-muted">
                    <span v-if="message.latitude">
                      <div>Position Report</div>
                      <div>
                        Coordinates: {{ message.latitude }}, {{ message.longitude }}
                      </div>
                      <div>
                        Altitude: {{ message.altitude }}
                      </div>
                    </span>
                    <span v-else>
                      No text or data.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageItemFooter :message="message" :enablePopover="enablePopover" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { MessageDecoder } from '@airframes/acars-decoder';
import MessageItemHeader from '@/components/messages/MessageItemHeader.vue';
import MessageItemFooter from '@/components/messages/MessageItemFooter.vue';

@Component({
  components: {
    MessageItemFooter,
    MessageItemHeader,
  },
})
export default class MessagesListItemSlim extends Vue {
  @Prop()
    message!: any;

  @Prop({ default: true })
    enablePopover!: boolean;

  decodedMessage: any = {};

  convertNewlinesToBRs(text: string) : string { // eslint-disable-line class-methods-use-this
    return text ? text.split('\n').join('<br>') : text;
  }

  decodeMessage(message: any) : boolean { // eslint-disable-line class-methods-use-this
    const decoder = new MessageDecoder();
    this.decodedMessage = decoder.decode(message);
    return this.decodedMessage.decoded;
  }

  showMessageDetail() {
    window.open(`/messages/${this.message.id}`, '_blank');
  }
}
</script>

<style scoped>
.inner-shadow {
  box-shadow: inset 0px 0px 10px 0px rgba(116, 116, 116, 0.1);
}
.bg-inner {
  background-color: rgba(230, 230, 230, 0.20);
}
</style>
