<template>
  <div class="about text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Your Dashboard</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <h3 class="mb-4">
          <img
            :src="avatar.imageUrl($store.state.auth.user, { size: 40 })"
            alt="Avatar"
            class="rounded-circle"
            width="40"
          >
          <span class="ml-2">
            {{ $store.state.auth.user.name }}
            <small class="text-muted">({{ $store.state.auth.user.role }})</small>
          </span>
          <div class="pull-right">
            <b-button
              v-b-modal.modal-settings
              variant="primary"
              class="mr-2"
              >Settings</b-button>
            <b-modal ok-only id="modal-settings" title="Settings">
              <p class="my-2">
                Manage your account settings.
              </p>
              <p class="my-2">
                Coming soon!
              </p>
            </b-modal>

            <b-button v-b-modal.modal-api-key>API Key</b-button>
            <b-modal ok-only id="modal-api-key" title="Your API Key">
              <p class="my-2">
                This API key is a unique identifier that allows you to access the API.
              </p>
              <p class="mb-4 my-2">{{ $store.state.auth.user.apiKey }}</p>
              <p class="my-2">
                Current Subscription Tier:
              </p>
              <p class="my-2">
                <span class="badge badge-primary">
                  Free
                </span>
              </p>
            </b-modal>
          </div>
        </h3>
        <h4 class="mb-4">Ground Stations</h4>
        <div v-if="$store.state.auth.user.stations.length === 0">
          <p class="text-muted">
            You have not added any ground stations to your account.
          </p>
        </div>
        <div v-else>
          <p class="text-muted">
            You have added {{ $store.state.auth.user.stations.length }} ground
            stations to your account.
          </p>
          <table class="table table-hover mb-4 bg-white">
            <tr>
              <th>ID</th>
              <th>Ident</th>
              <th>Type</th>
              <th>Nearest Airport</th>
              <th>Heard Messages</th>
              <th>Status</th>
              <th>Last Report</th>
            </tr>
            <tbody>
              <tr v-for="station in $store.state.auth.user.stations"
                :key="`station-table-row-${station.id}`"
                @click="handleStationClick(station)"
              >
                <td>
                  <span class="badge badge-primary text-white pl-2 pr-2">
                    {{ station.id }}
                  </span>
                </td>
                <td>
                  {{ station.ident }}
                </td>
                <td>
                  <span v-if="station.sourceType">
                    {{ station.sourceType.toUpperCase() }}
                  </span>
                  <span v-else class="text-muted">
                    UNKNOWN
                  </span>
                </td>
                <td>
                  <span v-if="station.nearestAirportIcao">
                    {{ station.nearestAirportIcao.toUpperCase() }}
                  </span>
                  <span v-else class="text-muted">
                    UNKNOWN
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="station.stationMessageCount" class="badge badge-pill badge-info">
                    {{ station.stationMessageCount.messagesCount }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="mr-1 p-1 badge" :class="statusBadgeClass(station.status)" >
                    {{ humanize(station.status) }}
                  </span>
                </td>
                <td>
                  {{ station.lastReportAt | moment("from", "now") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-button
          v-b-modal.modal-add-ground-station
          variant="outline-secondary"
          >Add Ground Station</b-button>
        <b-modal ok-only id="modal-add-ground-station" title="Add Ground Station">
          <p class="my-2">
            Add a ground station to your account via a helpful wizard.
          </p>
          <p class="my-2">
            Coming soon!
          </p>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';

import Avatar from '@/utilities/avatar';

@Component({
  components: {
  },
})
export default class UserDashboard extends Vue {
  avatar = Avatar;

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.refresh();
    }
  }

  mounted() {
    console.log('UserDashboard mounted');
    this.refresh();
  }

  refresh() {
    this.$store.dispatch('auth/fetchCurrentUserProfile');
  }

  statusBadgeClass(status: string) { // eslint-disable-line class-methods-use-this
    if (status === 'active') return 'badge-success';
    if (status === 'inactive') return 'badge-danger';
    return 'badge-info';
  }

  handleStationClick(station: any) {
    this.$router.push(`/user/station/${station.id}`);
  }

  humanize(str: string) { // eslint-disable-line class-methods-use-this
    const frags = str.split('-');
    for (let i = 0; i < frags.length; i += 1) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
}
</script>
