<template>
  <div id="nav" class="mt-0 pt-3 pb-3 border-bottom">
    <h3>Flights</h3>
    <router-link to="/flights">Live</router-link>
  </div>
</template>

<script>
export default {
  name: 'flights_nav',
};
</script>
