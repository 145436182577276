<template>
  <div>
    <b-table
      :selectable="false"
      :items="myProvider()"
      :fields="fieldsDefinition"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-null-last="true"
      bordered
      responsive="sm"
      small
      >
      <template #cell(flagImageUrl)="row">
        <img
          :src="row.item['flagImageUrl']"
          width="30" height="20" :title="row.item['countryName']"
          alt="Country Flag"
          />
      </template>
      <template #cell(ident)="row">
        <router-link :to="`/stations/${row.item['ident']}`">{{ row.item['ident'] }}</router-link>
      </template>
      <template #cell(user)="row">
        <span v-if="row.item.user">
          <router-link :to="`/users/${row.item.user.username}`" target="_blank">
            <img
              :src="row.item.user.gravatarUrl"
              width="20" height="20"
              :alt="`Avatar for ${row.item.user.username}`"
              class="rounded-circle"
              />
            {{ row.item.user.username }}
          </router-link>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment-timezone';

import { TextFormatter } from '@/utilities/text_formatter';
import { HostUtils } from '@/utilities/host_utils';

@Component
export default class StationsTable extends Vue {
  @Prop() private stations!: Array<any>;

  sortBy = 'lastReportAt';

  sortDesc = true;

  fieldsDefinition = [
    { key: 'flagImageUrl', label: '', sortable: true },
    { key: 'id', label: 'ID', sortable: true, class: 'text-left d-none d-md-table-cell' }, // eslint-disable-line object-curly-newline
    { key: 'ident', sortable: true, class: 'text-left text-nowrap text-truncate', style: 'max-width: 100px;' }, // eslint-disable-line object-curly-newline
    {
      key: 'user',
      sortable: true,
      class: 'd-none d-sm-table-cell text-left text-nowrap',
      sortByFormatted: (value: any) => {
        if (value) {
          return value.username;
        }
        return '';
      },
    },
    {
      key: 'nearestAirportIcao',
      label: 'Nearest Airport',
      sortable: true,
      class: 'd-none d-lg-table-cell',
    },
    { key: 'ipAddress', label: 'IP Address', sortable: true, class: 'd-none d-xl-table-cell' }, // eslint-disable-line object-curly-newline
    { key: 'sourceType', label: 'Ingest', sortable: true, class: 'd-none d-md-table-cell text-nowrap' }, // eslint-disable-line object-curly-newline
    {
      key: 'messagesCount',
      label: '# of Messages',
      sortable: true,
      class: 'd-none d-sm-table-cell text-right',
      formatter: (value: number) => (value != null ? TextFormatter.intToHuman(value) : 0),
    },
    { key: 'lastReportAt', label: 'Last Heard', sortable: true, class: 'text-right text-nowrap', formatter: (value: any, key: any, item: any) => { return moment(value).fromNow(); } }, // eslint-disable-line arrow-body-style, max-len, object-curly-newline
  ];

  hostUtils = new HostUtils();

  sourceTypeString(sourceType: String) { // eslint-disable-line class-methods-use-this
    let sourceTypeString;

    switch (sourceType) {
    case 'acars':
      sourceTypeString = 'ACARS';
      break;
    case 'ads-b':
      sourceTypeString = 'ADS-B';
      break;
    case 'ads-c':
      sourceTypeString = 'AERO ADS-C';
      break;
    case 'aero-acars':
      sourceTypeString = 'AERO ACARS';
      break;
    case 'aero-adsc':
      sourceTypeString = 'AERO ADS-C';
      break;
    case 'ais':
      sourceTypeString = 'AIS';
      break;
    case 'hfdl':
      sourceTypeString = 'HFDL';
      break;
    case 'iridium-acars':
      sourceTypeString = 'Iridium ACARS';
      break;
    case 'vdl':
      sourceTypeString = 'VDL';
      break;
    default:
      sourceTypeString = 'Unknown';
      break;
    }

    return sourceTypeString;
  }

  statusString(status: String) { // eslint-disable-line class-methods-use-this
    let statusString = 'Unknown';

    switch (status) {
    case 'active':
      statusString = 'Active';
      break;
    case 'inactive':
      statusString = 'Inactive';
      break;
    case 'pending-reception':
      statusString = 'Pending Reception';
      break;
    default:
      statusString = 'Unknown';
      break;
    }

    return statusString;
  }

  statusColorClass(status: String) { // eslint-disable-line class-methods-use-this
    let colorClass = '';

    switch (status) {
    case 'active':
      colorClass = 'success';
      break;
    case 'inactive':
      colorClass = 'danger';
      break;
    case 'pending-reception':
      colorClass = 'warning';
      break;
    default:
      colorClass = '';
      break;
    }

    return colorClass;
  }

  myProvider() {
    const items = [];
    for (const station of this.stations) { // eslint-disable-line no-restricted-syntax
      const mergedStation = {
        countryCode: station.countryCode,
        countryName: station.countryName,
        flagEmoji: station.flagEmoji,
        flagImageUrl: station.flagImageUrl,
        id: station.id,
        ident: station.ident,
        ipAddress: station.ipAddress || 'Unknown',
        lastReportAt: station.lastReportAt,
        messagesCount: station.messagesCount,
        nearestAirportIcao: station.nearestAirportIcao,
        sourceApplication: station.sourceApplication,
        sourceProtocol: station.sourceProtocol,
        sourceType: this.sourceTypeString(station.sourceType),
        status: this.statusString(station.status),
        user: station.user,
        _cellVariants: { status: this.statusColorClass(station.status) },
      };
      items.push(mergedStation);
    }
    return items || [];
  }
}
</script>

<style scoped>

</style>
