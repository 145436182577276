<template>
  <div class="feature-detail text-left">
    <div class="mb-1 border-bottom">
      {{ $props.feature.properties.airframe.tail }} / {{ $props.feature.properties.flight }}
    </div>
    <div v-if="$props.feature.properties.altitude">
      Altitude {{ $props.feature.properties.altitude }}
    </div>
    <div
      v-if="$props.feature.properties.departingAirport ||
        $props.feature.properties.destinationAirport"
      class="mt-1 small"
      >
      Flying from {{ $props.feature.properties.departingAirport }} to
      {{ $props.feature.properties.destinationAirport }}.
    </div>
    <div class="mt-1 small">
      There have been {{ $props.feature.properties.messagesCount }} related messages received.
    </div>
    <div class="mt-1">
      <router-link :to="`/flights/${$props.feature.properties.id}`">See Flight</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FlightOverlay extends Vue {
  @Prop() private feature!: any;
}

</script>
