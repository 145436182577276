<template>
  <div v-if="station" class="about text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Your Dashboard</h3>
      <router-link :to="`/user/station/${station.id}`">Ground Station Detail</router-link>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <h3 class="mb-0">
          {{ station.ident }}
          <div class="pull-right">
            <b-button
              :to="`/user/station/${id}/edit`"
              variant="warning"
              class="mr-2">
              Edit
            </b-button>
            <b-button variant="danger">Destroy</b-button>
          </div>
        </h3>
      </div>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <h4 class="mb-4">Specifications</h4>
        <div class="row">
          <div class="col-6">
            <table class="table table-hover mb-0 bg-white">
              <tr>
                <td>UUID</td>
                <td>{{ station.uuid }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{{ station.sourceType ? station.sourceType.toUpperCase() : "" }}</td>
              </tr>
              <tr>
                <td>Owner</td>
                <td>{{ $store.state.auth.user.name }}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>
                  {{ station.latitude || 'Unknown' }}, {{ station.longitude || 'Unknown' }}
                  <span v-if="station.useFuzzedLocation" class="text-muted">Fuzzed</span>
                </td>
              </tr>
              <tr>
                <td>Nearest Airport</td>
                <td>{{ station.nearestAirportIcao || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>Platform</td>
                <td>{{ station.systemPlatform || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>Operating System</td>
                <td>{{ station.systemOS || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>SDR</td>
                <td>{{ station.equipmentSdr || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>Filters</td>
                <td>{{ station.equipmentFilters || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>Antenna</td>
                <td>{{ station.equipmentAntenna || 'Unknown' }}</td>
              </tr>
              <tr>
                <td>Feeder Client</td>
                <td>{{ station.sourceApplication || 'Unknown' }}</td>
              </tr>
            </table>
          </div>
          <div class="col-6">
            <Map
              :coordinates="stationCoordinates()"
              style="height: 100%;"
              />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <h4 class="mb-4">Recent Messages</h4>
        <div v-if="recentMessages && recentMessages.length > 0">
          <MessagesSimpleTable :messages="recentMessages" />
        </div>
        <h5 v-else-if="loadingMessages" class="text-muted">Loading...</h5>
        <h5 v-else class="text-muted">No recent messages found.</h5>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import Map from '@/components/shared/Map.vue';
import MessagesSimpleTable from '@/components/messages/MessagesSimpleTable.vue';

@Component({
  components: {
    Map,
    MessagesSimpleTable,
  },
})
export default class UserStation extends Vue {
  id = 0;

  loadingMessages = false;

  recentMessages = [];

  station: any = null;

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    this.refresh();
  }

  mounted() {
    this.refresh();
  }

  fetchRecentMessagesForStation() {
    console.log('Fetching messages for station.');
    this.loadingMessages = true;
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/messages?station_ids=${this.station.id}&limit=10`,
      method: 'GET',
    }).then((response) => {
      this.recentMessages = response.data;
      this.loadingMessages = false;
    });
  }

  fetchStation() {
    console.log('Fetching station.');
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/stations/${this.id}`,
      method: 'GET',
    }).then((response) => {
      this.station = response.data;
      if (this.station) {
        console.log('Station loaded.', this.station);
        this.fetchRecentMessagesForStation();
      }
    });
  }

  refresh() {
    this.id = Number(this.$route.params.id);
    this.fetchStation();
  }

  stationCoordinates() {
    const coords = { latitude: this.station.latitude, longitude: this.station.longitude };
    return coords;
  }
}
</script>
