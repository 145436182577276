import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment-timezone';
import axios from 'axios';

import acarsData from '../assets/acars-data.json';
import auth from './auth.module';
import userStation from './userStation.module';

Vue.use(Vuex);

interface State {
  acarsData: any
  activeFlights: any[]
  airframes: any[]
  airports: any[]
  messagesLive: any[]
  apiServerBaseUrl: String
  clients: any
  ingestStats: any[]
  ingestStatsLast: any[]
  isConnected: Boolean
  isProduction: Boolean
  isLiveMessagesPaused: Boolean
  messagesCountSinceIntervalStart: Number
  messagesHistorical: any[]
  messagesLivePerSecond: Number
  messagesLiveCountLast: Number
  lastMessageFromAirframes: any[]
  lastMessageFromStations: any[]
  lastSyncAirports: any
  stations: any[]
  stats: any
  users: any[]
}

export default new Vuex.Store<State>({
  modules: {
    auth,
    userStation,
  },
  state: {
    acarsData,
    activeFlights: [],
    airframes: [],
    airports: [],
    apiServerBaseUrl: process.env.NODE_ENV === 'production' ? 'https://api.airframes.io' : 'http://localhost:3001',
    clients: {},
    ingestStats: [],
    ingestStatsLast: [],
    isConnected: false,
    isProduction: process.env.NODE_ENV === 'production',
    isLiveMessagesPaused: false,
    messagesCountSinceIntervalStart: 0,
    messagesHistorical: [],
    messagesLive: [] as any[],
    messagesLivePerSecond: 0,
    messagesLiveCountLast: 0,
    lastMessageFromAirframes: [],
    lastMessageFromStations: [],
    lastSyncAirports: 0,
    stations: [],
    stats: {},
    users: [],
  },
  actions: {
    async fetchAirports({ state, commit }) {
      if (state.lastSyncAirports === 0 || moment().diff(state.lastSyncAirports, 'hours') > 1) {
        const airports = await axios.get(`${state.apiServerBaseUrl}/airports`);
        commit('setAirports', airports.data);
        commit('setLastSyncAirports', moment().unix());
      }
    },
    socket_activeFlights({ commit }, flights) {
      commit('setActiveFlights', flights);
    },
    socket_message({ commit }, message) {
      // console.log('Store: Socket: message');
      commit('accountForMessageRate', [message]);
      commit('prependNewLiveMessages', [message]);
    },
    socket_airframes({ commit }, airframes) {
      commit('setAirframes', airframes);
    },
    socket_clients({ commit }, clients) {
      commit('socket_clients', clients);
    },
    socket_ingests({ commit }, ingests) {
      commit('setIngestStats', ingests);
    },
    socket_stations({ commit }, stations) {
      // console.log('Store: Socket: stations');
      commit('setStations', stations.sort((a: any, b: any) => (b.messagesCount - a.messagesCount)));
    },
    socket_users({ commit }, users) {
      commit('setUsers', users);
    },
    socket_windowReload({ commit }) {
      console.log('Store: Socket: windowReload');
      window.location.reload();
    },
  },
  mutations: {
    socket_clients(state, clients) {
      // console.log('Store: socket_clients');
      Vue.set(state, 'clients', clients);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    socket_connect(state, _status) {
      // console.log('Store: socket_connect');
      state.isConnected = true;
    },
    accountForMessageRate(state: any, newMessages: Array<any>) {
      Vue.set(state, 'messagesCountSinceIntervalStart', state.messagesCountSinceIntervalStart + newMessages.length);
    },
    calculateMessagesLivePerSecond(state: any) {
      Vue.set(state, 'messagesLivePerSecondLast', state.messagesLivePerSecond);
      if (!state.isLiveMessagesPaused) {
        const mps = state.messagesCountSinceIntervalStart / 5;
        Vue.set(state, 'messagesLivePerSecond', mps);
      } else {
        Vue.set(state, 'messagesLivePerSecond', 0);
      }
      Vue.set(state, 'messagesCountSinceIntervalStart', 0);
    },
    initializeStore(state) {
      const existingAirframes = localStorage.getItem('airframes');
      if (existingAirframes) {
        Vue.set(state, 'airframes', JSON.parse(existingAirframes));
      }

      const existingStations = localStorage.getItem('stations');
      if (existingStations) {
        Vue.set(state, 'stations', JSON.parse(existingStations));
      }
    },
    pauseLiveMessages(state: any) {
      console.log('Store: pauseLiveMessages');
      Vue.set(state, 'isLiveMessagesPaused', true);
    },
    playLiveMessages(state: any) {
      console.log('Store: playLiveMessages');
      Vue.set(state, 'isLiveMessagesPaused', false);
    },
    prependNewLiveMessages(state: any, newMessages: Array<any>) {
      // console.log('Store: prependNewLiveMessages');
      Vue.set(state, 'messagesLive', newMessages.concat(state.messagesLive).slice(0, 100));
    },
    setAirframes(state: any, airframes: Array<any>) {
      // console.log('Store: setAirframes');
      Vue.set(state, 'airframes', airframes);
    },
    setAirports(state: any, airports: Array<any>) {
      // console.log('Store: setAirports');
      Vue.set(state, 'airports', airports);
    },
    setActiveFlights(state: any, flights: Array<any>) {
      console.log('Store: setActiveFlights');
      Vue.set(state, 'activeFlights', flights);
    },
    setIngestStats(state: any, ingests: Array<any>) {
      const newIngests = ingests.map((ingest: any) => {
        const lastIngest = state.ingestStats.find((i: any) => i.ingest === ingest.ingest);
        if (lastIngest) {
          ingest['packets.session.diff'] = ingest['packets.session'] - lastIngest['packets.session']; // eslint-disable-line no-param-reassign, max-len
        }
        return ingest;
      });

      Vue.set(state, 'ingestStatsLast', state.ingestStats);
      Vue.set(state, 'ingestStats', newIngests);
    },
    setLastHeardFromAirframe(state: any, tail: any) {
      // console.log('Store: setLastHeardFromAirframe');
      if (!state.isLiveMessagesPaused) {
        let { lastMessageFromAirframes } = state;

        const filter = function t(last: any) {
          const lastDate = moment(last.when);
          const now = moment();
          const diff = now.diff(lastDate, 'minutes');
          return last.tail !== tail && diff < 5;
        };

        lastMessageFromAirframes = lastMessageFromAirframes.filter(filter);

        const last = { tail, when: Date.now() };
        lastMessageFromAirframes.push(last);
        lastMessageFromAirframes = lastMessageFromAirframes.sort((a: any, b: any) => (b.when - a.when)); // eslint-disable-line max-len

        Vue.set(state, 'lastMessageFromAirframes', lastMessageFromAirframes);
      }
    },
    setLastSyncAirports(state: any, lastSync: any) {
      // console.log('Store: setLastSyncAirports');
      Vue.set(state, 'lastSyncAirports', lastSync);
    },
    setStations(state: any, stations: Array<any>) {
      // console.log('Store: setStations');
      Vue.set(state, 'stations', stations);
    },
    setStats(state: any, stats: any) {
      console.log('Store: setStats');
      Vue.set(state, 'stats', stats);
    },
    setUsers(state: any, users: Array<any>) {
      Vue.set(state, 'users', users);
    },
  },
});
