export class TextFormatter {
  static intToHuman(int: number): string {
    return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static humanize(str: string): string {
    const frags = str.split('-');
    for (let i = 0; i < frags.length; i += 1) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
}

export default {};
