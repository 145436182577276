import { render, staticRenderFns } from "./MessageItemFooter.vue?vue&type=template&id=8556030a&scoped=true"
import script from "./MessageItemFooter.vue?vue&type=script&lang=ts"
export * from "./MessageItemFooter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8556030a",
  null
  
)

export default component.exports