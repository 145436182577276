<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Ingest Stats</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        Realtime stats of the Ingests. Updates every 1 minute.
        The first load will take up to 1 minute.
      </div>
    </div>
    <div class="container p-4">
      <h4>Packets</h4>
      <table class="mt-4 table table-bordered">
        <thead>
          <tr>
            <th class="text-left">Ingest</th>
            <th class="text-left">Packets per Minute</th>
            <th class="text-left">Packets Session</th>
            <th class="text-left">Packets All-Time</th>
            <th class="text-right">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ingest in ingests" :key="`ingest-stat-${ingest['ingest']}`" class="small">
            <td class="p-2 text-left">
              {{ ingest['ingest'] }}
            </td>
            <td class="p-2 text-right">
              {{ ingest['packets.session.diff'] }}
            </td>
            <td class="p-2 text-right">
              {{ ingest['packets.session'] }}
            </td>
            <td class="p-2 text-right">
              {{ ingest['packets.all-time'] }}
            </td>
            <td class="p-2 text-right">
              <span v-if="ingest['packets.session.diff'] == 0">
                Down? (No Packets)
              </span>
              <span v-else>
                Ok (Receiving Packets)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class IngestStats extends Vue {
  ingests: any = [];

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setIngestStats') {
        this.ingests = state.ingestStats;
      }
    });
  }
}
</script>
