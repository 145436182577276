<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="bg-light">
    <div class="row" @click="openDetailLink">
      <div class="col-12">
        <div class="p-1 text-dark text-left">
          <small>
            <div class="p-2 float-left">
              <span v-if="!!$props.message.airframe && !!$props.message.airframe.tail">
                <span class="text-muted">Tail </span>
                <a
                  :href="`/airframes/${$props.message.airframe.id}`"
                  @click.stop
                  target="_blank"
                  >{{ $props.message.airframe.tail }}</a>
              </span>

              <span v-if="!!$props.message.airframe && !!$props.message.airframe.icao">
                <span class="ml-2 text-muted">ICAO </span>
                <a
                  :href="`/airframes/${$props.message.airframe.id}`"
                  @click.stop
                  target="_blank"
                  >{{ $props.message.airframe.icao }}</a>
              </span>

              <span v-if="!!$props.message.flight && !!$props.message.flight.flight">
                <span class="ml-2 text-muted">Flight </span>
                <a
                  :href="`/flights/${$props.message.flight.id}`"
                  @click.stop
                  target="_blank"
                  >{{ $props.message.flight.flight }}</a>
              </span>
            </div>
            <div class="p-2 float-right">
              <span v-if="!!$props.message.frequency">
                <span class="ml-1 text-muted">F: </span>
                <a
                  :id="`message-${$props.message.id}-frequency`"
                  href="#"
                  @click.stop
                  >
                  {{ $props.message.frequency }}
                </a>
                <b-popover
                  v-if="!!$props.enablePopover"
                  :target="`message-${$props.message.id}-frequency`"
                  triggers="hover"
                  placement="top"
                  >
                  <template v-slot:title>Frequency: {{ $props.message.frequency }} MHz</template>
                  {{ frequencyName($props.message.frequency) }}
                </b-popover>
              </span>

              <span v-if="!!$props.message.mode">
                <span class="ml-1 text-muted">M: </span>
                <a
                  :id="`message-${$props.message.id}-mode`"
                  href="#"
                  @click.stop
                  >{{ $props.message.mode }}</a>
                <b-popover
                  v-if="!!$props.enablePopover"
                  :target="`message-${$props.message.id}-mode`"
                  triggers="hover"
                  placement="top"
                  >
                  <template v-slot:title>Mode: {{ $props.message.mode }}</template>
                  Mode {{ $props.message.mode }}
                </b-popover>
              </span>

              <span v-if="!!$props.message.label">
                <span class="ml-1 text-muted">L: </span>
                <a
                  :title="labelName($props.message.label)"
                  :id="`message-${$props.message.id}-label`"
                  href="#"
                  @click.stop
                  >{{ $props.message.label }}</a>
                <b-popover
                  v-if="!!$props.enablePopover"
                  :target="`message-${$props.message.id}-label`"
                  triggers="hover"
                  placement="top"
                  >
                  <template v-slot:title>Label: {{ $props.message.label }}</template>
                  {{ labelName($props.message.label) }}
                </b-popover>
              </span>

              <span v-if="!!$props.message.blockId">
                <span class="ml-1 text-muted">B:</span>
                {{ $props.message.blockId }}
              </span>

              <span v-if="!!$props.message.messageNumber">
                <span class="ml-1 text-muted">M#:</span>
                {{ $props.message.messageNumber }}
              </span>

              <span v-if="$props.message.error >= 1" class="ml-1 text-danger">
                <strong>E:</strong>
                {{ $props.message.error }}
              </span>
            </div>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MessageItemFooter extends Vue {
  @Prop() message!: any;

  @Prop({ default: true }) enablePopover!: boolean;

  frequencyName(frequency: number) : string {
    const frequencyString = frequency.toFixed(3);
    const frequencyObj = this.$store.state.acarsData.frequencies[frequencyString];
    let frequencyName = 'Unknown';

    if (frequencyObj) {
      frequencyName = frequencyObj.name;
    }

    return frequencyName;
  }

  labelName(label: string) : string {
    const labelObj = this.$store.state.acarsData.labels[label];
    let labelName = 'Unknown';

    if (labelObj) {
      labelName = labelObj.name;
    }

    return labelName;
  }

  openDetailLink() {
    window.open(`/messages/${this.message.id}`, '_blank');
  }
}
</script>

<style scoped>
</style>
