<template>
  <div class="feature-detail airport-overlay text-left">
    <div class="m-0">
      {{ $props.feature.properties.name }}
    </div>
    <div class="mb-2 pb-2 border-bottom text-muted">
      {{
        [$props.feature.properties.municipality,
        $props.feature.properties.region, $props.feature.properties.country].join(', ')
      }}
    </div>
    <div v-if="$props.feature.properties.icao">
      ICAO {{ $props.feature.properties.icao }}
    </div>
    <div v-if="$props.feature.properties.iata">
      IATA {{ $props.feature.properties.iata }}
    </div>
    <div v-if="$props.feature.properties.altitude">
      Altitude {{ $props.feature.properties.altitude }}
    </div>
    <div v-if="$props.feature.properties.frequencies.length > 0" class="mt-2 pt-2 border-top">
      Frequencies
      <table class="small">
        <tr v-for="frequency in $props.feature.properties.frequencies"
          :key="`freq-${frequency.id}`"
        >
          <td class="px-1">
            {{ typeToString(frequency.type) }}
          </td>
          <td class="px-1 text-right">
            <span v-if="frequency.feeds.length > 0">
              <a :href="frequency.feeds[0].url" target="_blank">
                {{ frequency.frequency }}
              </a>
            </span>
            <span v-else>
              {{ frequency.frequency }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-2">
      <router-link :to="`/airports/${$props.feature.properties.id}`">See Airport</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AirportOverlay extends Vue {
  @Prop() private feature!: any;

  // eslint-disable-next-line class-methods-use-this
  typeToString(type: string): string {
    switch (type) {
    case 'A/D':
      return 'Approach/Departure';
    case 'APP':
      return 'Approach';
    case 'ARTC':
      return 'Air Route Traffic Control';
    case 'ASOS':
      return 'Automated Surface Observing System';
    case 'ATIS':
      return 'Automated Terminal Information Service';
    case 'AWOS':
      return 'Automated Weather Observing System';
    case 'CLD':
      return 'Clearance Delivery';
    case 'CTAF':
      return 'Common Traffic Advisory Frequency';
    case 'DEP':
      return 'Departure';
    case 'GND':
      return 'Ground';
    case 'MISC':
      return 'Miscellaneous';
    case 'RDO':
    case 'TWR':
      return 'Tower';
    case 'UNIC':
      return 'UNICOM';
    default:
      return type;
    }
  }
}

</script>

<style scoped>
.airport-overlay {
  width: 300px;
}
</style>
