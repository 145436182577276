<template>
  <div class="text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Partners</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container">
        Airframes doesn't exist in a silo. We're proud to work closely with the
        following organizations. We wouldn't be here without the help and support
        of our partners.
      </div>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <h3>ADS-B Aggregators</h3>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://airplanes.live" target="_blank">
          <img src="/airplanes-live.png"
            width="200" height="200" class="mb-2" alt="Airplanes.Live Logo">
          <div>Airplanes.Live</div>
        </a>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://plane.watch" target="_blank">
          <img src="https://plane.watch/assets/pw-logo-only-icon-bc608d73490b71b5bf2c1fe82c7cf04ad9b60fba.svg"
            width=200 height=200 class="p-4 mb-2" style="object-cover: fill;"
            alt="Plane Watch Logo">
          <div>Plane Watch</div>
        </a>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://grndcntrl.net" target="_blank">
          <img src="https://grndcntrl.net/Images/HHR_reduced.jpg" width=200 height=200 alt="Ground Control Logo" class="p-4 mb-2" style="object-cover: fill;">
          <div>Ground Control</div>
        </a>
      </div>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <h3>Communities</h3>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://sdr-e.com" target="_blank">
          <img src="https://avatars.githubusercontent.com/u/98966214?s=200&v=4" class="mb-2" alt="SDR Enthusiasts Logo">
          <div>SDR Enthusiasts</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Partners extends Vue {
}
</script>

<style scoped>
.partner:hover {
  border-color: #ccc !important;
}
</style>
