import CryptoJS from 'crypto-js';

export default class Avatar {
  public static imageUrl(user: any, options: any): string {
    const size = options.size || 24;
    const defaultUrl = options.defaultUrl || `https://gravatar.com/avatar/00000000000000000000000000000000?s=${size}&d=mp`;
    if (user) {
      if (user.avatar) {
        return user.avatar;
      }
      if (user.email && user.email.length > 0) {
        return Avatar.gravatarUrl(user.email, { size });
      }
    }
    return defaultUrl;
  }

  public static gravatarUrl(email: string, options: any) {
    const size = options.size || 24;
    const md5 = CryptoJS.MD5(email);
    const defaultUrl = options.defaultUrl || `https://ui-avatars.com/api/${encodeURIComponent(email)}/${size}`;
    return `https://www.gravatar.com/avatar/${md5}?s=${size}&d=${encodeURIComponent(defaultUrl)}`;
  }
}
