<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Stations</h3>
    </div>
    <div class="border-bottom">
      <div class="container-lg p-4">
        The following is a list of feeder stations delivering messages heard by SDR equipment
        located at various locations around the world. The number of messages and last heard
        <strong>update every 5 seconds</strong> and reflect near realtime status of these stations.
      </div>
    </div>
    <div class="p-4">
      <div v-if="pendingStations.length > 0" class="mb-4">
        <h4>Pending</h4>
        <p>
          These stations have recently submitted reports and will be activated within 24 hours.
        </p>
        <StationsTable :stations="pendingStations" class="bg-white" />
      </div>
      <div v-if="activeStations.length > 0">
        <h4>Active</h4>
        <p>
          These {{ activeStations.length }} stations have been active for
          over 24 hours and have been heard from within 30 days.
        </p>
        <div class="mt-2 mb-3 card-deck">
          <div class="card p-4 border">
            <div class="text-muted">ACARS</div>
            <h2 class="m-0 mt-2">
              {{ stationCount('acars') }}
            </h2>
          </div>
          <div class="card p-4 border">
            <div class="text-muted">VDL</div>
            <h2 class="m-0 mt-2">
              {{ stationCount('vdl') }}
            </h2>
          </div>
          <div class="card p-4 border">
            <div class="text-muted">HFDL</div>
            <h2 class="m-0 mt-2">
              {{ stationCount('hfdl') }}
            </h2>
          </div>
          <div class="card p-4 border">
            <div class="text-muted">SATCOM</div>
            <h2 class="m-0 mt-2">
              {{ stationCount('satcom') }}
            </h2>
          </div>
          <div class="card p-4 border">
            <div class="text-muted">AIS</div>
            <h2 class="m-0 mt-2">
              {{ stationCount('ais') }}
            </h2>
          </div>
        </div>
        <StationsTable :stations="activeStations" class="bg-white" />
      </div>
      <div v-if="inactiveStations.length > 0" class="mt-4">
        <h4>Inactive</h4>
        <p>
          These stations have not been heard from within the last 30 days.
        </p>
        <StationsTable :stations="inactiveStations" class="bg-white" />
      </div>
      <div v-else>
        <LoadingDots loadingText="Getting stations list..." />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import StationsTable from '@/components/StationsTable.vue';
import LoadingDots from '@/components/shared/LoadingDots.vue';

@Component({
  components: {
    LoadingDots,
    StationsTable,
  },
})
export default class Stations extends Vue {
  errorFetchingStations = false;

  fetchInterval?: number;

  fetchingStations = false;

  stations = [];

  mounted() {
    this.stations = [];
    this.refresh();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.refresh();
    }, 5000);
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.stations = [];
      this.refresh();
      this.clearIntervals();
      this.fetchInterval = window.setInterval(() => {
        this.refresh();
      }, 5000);
    }
  }

  beforeDestroy() {
    this.clearIntervals();
  }

  beforeUnmount() {
    this.clearIntervals();
  }

  destroyed() {
    this.clearIntervals();
  }

  unmounted() {
    this.clearIntervals();
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  refresh() {
    this.fetchStations();
  }

  async fetchStations() {
    this.fetchingStations = true;
    const url = 'https://api.airframes.io/stations';
    Vue.axios.get(url).then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        this.fetchingStations = false;
        this.errorFetchingStations = true;
      } else {
        this.stations = response.data;
        this.fetchingStations = false;
      }
    }).catch((error) => {
      console.error(error);
      this.fetchingStations = false;
    });
  }

  get activeStations() {
    return this.stations.filter((s: any) => s.status === 'active');
  }

  get inactiveStations() {
    return this.stations.filter((s: any) => s.status === 'inactive');
  }

  get pendingStations() {
    return this.stations.filter((s: any) => s.status === 'pending-reception');
  }

  stationCount(type: string) {
    let count = 0;
    switch (type) {
    case 'satcom':
      count = this.stations.filter((s: any) => s.status === 'active').filter((s: any) => ['aero-acars', 'aero-adsc', 'iridium-acars'].includes(s.sourceType)).length; // eslint-disable-line max-len
      break;
    case 'acars':
      count = this.stations.filter((s: any) => s.status === 'active').filter((s: any) => s.sourceType === 'acars').length; // eslint-disable-line max-len
      break;
    case 'ais':
      count = this.stations.filter((s: any) => s.status === 'active').filter((s: any) => s.sourceType === 'ais').length; // eslint-disable-line max-len
      break;
    case 'hfdl':
      count = this.stations.filter((s: any) => s.status === 'active').filter((s: any) => s.sourceType === 'hfdl').length; // eslint-disable-line max-len
      break;
    case 'vdl':
      count = this.stations.filter((s: any) => s.status === 'active').filter((s: any) => s.sourceType === 'vdl').length; // eslint-disable-line max-len
      break;
    default:
      count = 0;
    }
    return count;
  }
}
</script>
