<template>
  <div class="home" v-if="!!user">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <img
        :src="user.gravatarUrl"
        :alt="`Avatar for ${user.username}`"
        class="rounded-circle border align-middle"
        width="200"
        height="200"
        />
      <h3 class="m-0 mt-2">{{ user.username }}</h3>
      <p class="text-muted">
        Member for {{ user.createdAt | moment("from", true) }}
      </p>
    </div>
    <div class="container p-4">
      <h4>Stations</h4>
      <table class="table table-hover mb-4 bg-white">
          <tr>
            <th>ID</th>
            <th class="text-left">Ident</th>
            <th>Type</th>
            <th>Nearest Airport</th>
            <th>Heard Messages</th>
            <th>Status</th>
            <th class="text-right">Last Report</th>
          </tr>
          <tbody>
            <tr v-for="station in user.stations"
              :key="`station-table-row-${station.id}`"
              style="cursor: pointer"
              @click="goToStation(station.ident)"
            >
              <td>
                <span class="badge badge-primary text-white pl-2 pr-2">
                  {{ station.id }}
                </span>
              </td>
              <td class="text-left">
                {{ station.ident }}
              </td>
              <td>
                <span v-if="station.sourceType">
                  {{ station.sourceType.toUpperCase() }}
                </span>
                <span v-else class="text-muted">
                  UNKNOWN
                </span>
              </td>
              <td>
                <span v-if="station.nearestAirportIcao">
                  {{ station.nearestAirportIcao.toUpperCase() }}
                </span>
                <span v-else class="text-muted">
                  UNKNOWN
                </span>
              </td>
              <td class="text-center">
                <span v-if="station.stationMessageCount" class="badge badge-pill badge-info">
                  {{ station.stationMessageCount.messagesCount }}
                </span>
              </td>
              <td class="text-center">
                <span class="mr-1 p-1 badge" :class="statusBadgeClass(station.status)" >
                  {{ textFormatter.humanize(station.status) }}
                </span>
              </td>
              <td class="text-right">
                {{ station.lastReportAt | moment("from", "now") }}
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { TextFormatter } from '@/utilities/text_formatter';

@Component({
  components: {
  },
})
export default class UserProfile extends Vue {
  user: any = {};

  textFormatter = TextFormatter;

  mounted() {
    this.fetchUser();
  }

  fetchUser() {
    console.log('Fetching user.');
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/users/username/${this.$route.params.username}`,
      method: 'GET',
    }).then((response) => {
      this.user = response.data;
    });
  }

  goToStation(ident: string) {
    this.$router.push(`/stations/${ident}`);
  }

  statusBadgeClass(status: string) { // eslint-disable-line class-methods-use-this
    if (status === 'active') return 'badge-success';
    if (status === 'inactive') return 'badge-danger';
    return 'badge-info';
  }
}
</script>
