<template>
  <div class="m-4 p-4">
    <h2 class="mb-4">
      Looking up the AcarsHub message from the server...
    </h2>
    <div v-if="fetchingMessage">
      <LoadingDots :loadingText="'Fetching message...'" />
    </div>
    <div v-else-if="errorFetchingMessage">
      <div class="alert alert-danger">
        <div class="font-weight-bold">
          <b-icon-exclamation-triangle-fill class="mr-1"></b-icon-exclamation-triangle-fill>
          No message found ({{ errorFetchingMessageMessage }}).
        </div>
        <div>
          If you have arrived here expecting to find a message,
          please discuss with Airframes support.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';

import LoadingDots from '@/components/shared/LoadingDots.vue';

@Component({
  components: { LoadingDots },
})
export default class AcarsRouterRedirect extends Vue {
  fetchingMessage = false;

  errorFetchingMessage = false;

  errorFetchingMessageMessage = '';

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.fetchMessage();
    }
  }

  mounted() {
    this.fetchMessage();
  }

  fetchMessage() {
    const { id } = this.$route.params;
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(id)) {
      this.fetchingMessage = true;
      const url = `https://toad.airframes.io/v1/aircraft/messages/ar/${id}`;
      Vue.axios.get(url).then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingMessage = false;
          this.errorFetchingMessage = true;
          this.errorFetchingMessageMessage = response.data.error;
        } else {
          this.fetchingMessage = false;
          if (!response.data.error) {
            const { message } = response.data;
            if (message) {
              this.$router.push(`/messages/${message.id}`);
            } else {
              this.errorFetchingMessage = true;
              this.errorFetchingMessageMessage = 'No message found';
            }
          }
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingMessage = false;
        this.errorFetchingMessage = true;
        this.errorFetchingMessageMessage = error.message;
      });
    } else {
      this.fetchingMessage = false;
      this.errorFetchingMessage = true;
      this.errorFetchingMessageMessage = 'Invalid ID';
    }
  }
}
</script>
