<template>
  <div id="app" class="height: 100%;">
    <div class="d-flex vh-100 flex-column">
      <MainNavigation />
      <div
        class="bg-light"
        style="height: -webkit-calc(100% - 88px); height: calc(100% - 88px); overflow-y: scroll;"
        >
        <keep-alive>
          <router-view/>
        </keep-alive>
      </div>
      <div class="flex-row" style="height: 32px;">
        <div id="status_bar"
            class="p-1 px-4 bg-white border-top d-flex justify-content-between">
          <a
            :href="`https://github.com/airframesio/frontend/releases/tag/v${version.version()}`"
            target="_blank"
            class="font-weight-light text-muted">
            {{ version.toString() }}
          </a>
          <div>
            <a href="/about" class="font-weight-light text-muted">About</a> |
            <a href="https://docs.airframes.io" target="_blank" class="font-weight-light text-muted">Docs</a> |
            <a href="/faq" class="font-weight-light text-muted">FAQ</a> |
            <a href="https://github.com/airframesio" target="_blank" class="font-weight-light text-muted">GitHub</a> |
            <a href="/partners" class="font-weight-light text-muted">Partners</a> |
            <a href="https://www.patreon.com/Airframes" target="_blank" class="font-weight-light text-muted">Patreon</a> |
            <a href="https://shop.airframes.io" target="_blank" class="font-weight-light text-muted">Shop</a>
          </div>
          <span
            class="font-weight-bold"
            :class="{ 'text-success': $socket.connected, 'text-danger': !$socket.connected }"
            title="Status of websocket to the server, not the status of service itself."
            >
            WS {{ $socket.connected ? 'Connected' : 'Disconnected' }}
          </span>
          <!-- <span class="font-weight-light text-muted">
            <span title="Messages per Second">{{ $store.state.messagesLivePerSecond }} m/s</span>
            <span v-if="$store.state.isLiveMessagesPaused" class="ml-2">
              <font-awesome-icon
                icon="play-circle"
                v-on:click="playLiveMessages()"
                />
            </span>
            <span v-else class="ml-2">
              <font-awesome-icon
                icon="pause-circle"
                v-on:click="pauseLiveMessages()"
                />
            </span>
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MainNavigation from '@/components/layout/MainNavigation.vue';
import { Version } from '@/utilities/version';

@Component({
  components: { MainNavigation },
})
export default class App extends Vue {
  messagesLivePerSecondInterval = 0;

  version = new Version();

  mounted() {
    this.$gtag.event('main_page_mounted');
    this.$data.messagesLivePerSecondInterval = setInterval(this.updateMessagesListPerSecond, 5000);
  }

  pauseLiveMessages() {
    console.log('Pausing live messages.');
    this.$store.commit('pauseLiveMessages');
  }

  playLiveMessages() {
    console.log('Playing live messages.');
    this.$store.commit('playLiveMessages');
  }

  updateMessagesListPerSecond() {
    this.$store.commit('calculateMessagesLivePerSecond');
  }
}

</script>

<style lang="scss">

#body {
  overflow-y: hidden;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  background-color: #4FE3C2 !important;
  color: #2c3e50 !important;
  border-color: #2c3e50;
}

#nav {
  padding: 20px;
}

</style>
