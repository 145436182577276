<template>
  <table class="table table-hover mb-0 bg-white">
    <thead>
      <tr>
        <th>Time</th>
        <th>Source</th>
        <th>Label</th>
        <th>Message</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="message in messages" :key="message.id">
        <td class="text-nowrap">
          <a
            :href="`/messages/${message.id}`"
            >
            {{ message.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
          </a>
        </td>
        <td>
          {{ message.sourceType.toUpperCase() }}
        </td>
        <td>
          {{ message.label }}
        </td>
        <td>
          {{ message.text }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MessagesSimpleTable extends Vue {
  @Prop({ required: true }) messages!: any[];
}

</script>
