<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Airframes</h3>
    </div>
    <div class="border-bottom">
      <div class="container p-4">
        Information about all known airframes.
      </div>
    </div>
    <div class="p-4 text-left">
      <table class="table table-hover mb-4 bg-white">
        <tr class="bg-dark text-light">
          <th>ID</th>
          <th>ICAO</th>
          <th>Tail</th>
          <th>ICAO Type</th>
          <th>Manufacturer</th>
          <th>Model</th>
          <th>Owner</th>
          <th>Airline</th>
          <th class="text-right">Updated</th>
        </tr>
        <tr
          v-for="airframe in airframes"
          :key="`airframe-card-${airframe.id}`"
          >
          <td>{{ airframe.id }}</td>
          <td>{{ airframe.icao }}</td>
          <td>{{ airframe.tail }}</td>
          <td>{{ airframe.icaoType }}</td>
          <td>{{ airframe.manufacturer }}</td>
          <td>{{ airframe.manufacturerModel }}</td>
          <td>{{ airframe.owner }}</td>
          <td>{{ airframe.airline? airframe.airline.name : '' }}</td>
          <td class="text-right">{{ airframe.updatedAt | moment('from', 'now') }}</td>
        </tr>
      </table>
      <div class="mx-auto text-center">
        <button
          v-if="pageNumber > 1"
          class="mr-2 btn btn-primary"
          @click="pageNumber = pageNumber - 1"
          >
          Previous
        </button>
        <div v-if="results" class="d-inline-block mx-2">
          Page {{ pageNumber }} of {{ results.meta.totalPages }}
        </div>
        <button
          v-if="airframes.length > 0"
          class="ml-2 btn btn-primary"
          @click="pageNumber = pageNumber + 1"
          >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import moment from 'moment-timezone';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
  components: {
  },
})
export default class Airframes extends Vue {
  airframes: any[] = [];

  limit = 20;

  pageNumber: number = 1;

  results: any = null;

  @Watch('pageNumber')
  onPaged(newValue: number, oldValue: number) {
    if (newValue !== oldValue) {
      const query: any = { ...this.$route.query, page: newValue };
      const newLocation: RawLocation = { query };
      this.$router.push(newLocation).catch(() => {});
      this.fetchAirframes();
    }
  }

  mounted() {
    this.setPageFromQuery();
    this.fetchAirframes();
  }

  async fetchAirframes() {
    const response = await axios.get(`${this.$store.state.apiServerBaseUrl}/airframes?limit=${this.limit}&page=${this.pageNumber}&sort=icao`);
    const results = response.data;
    this.airframes = results.items;
    this.results = results;
  }

  setPageFromQuery() {
    if (this.$route.query.page && !Number.isNaN(Number(this.$route.query.page))) {
      if (Number(this.$route.query.page) !== this.pageNumber) {
        this.pageNumber = Number(this.$route.query.page);
      }
    }
  }
}
</script>
